import "../style/page.css";

export function HomeVisits() {
  return (
    <>
      <div className="content-container">
        <div className="content">
          <div className="page-title">Home visits</div>
          <p>
            Home visits are at the discretion of the doctor. Please do not ask
            the doctor to call unless the patient is too ill to attend the
            surgery. Please try to request a home visit before 10.30am on that
            day. The receptionist will ask for full details, a telephone number
            and the reason for the visit. This information enables the doctors
            to plan their calls and allows urgent visitors to be dealt with as
            soon as possible.
          </p>
          <p>
            <b>
              Lack of transportation is not a reason for a doctor to make a home
              visit.
            </b>
          </p>
        </div>
      </div>
    </>
  );
}
