export const ADDRESS = [
  "Kingsthorpe Medical Centre",
  "Eastern Avenue South",
  "Northampton",
  "NN2 7JN",
];

export const CALL_AFTER_APPOINTMENTS = "11am";

export const CALL_AFTER_TEST_RESULTS = "2pm";

export const CQC_URL = "https://www.cqc.org.uk/location/1-674894176";

export const EMAIL_ADDRESS = "mailto:Kingsthorpe.K83035@nhs.net";

export const EMAIL = <a href={EMAIL_ADDRESS}>Kingsthorpe.K83035@nhs.net</a>;

export const EMAIL_REPEAT_PRESCRIPTIONS = (
  <a href={EMAIL_ADDRESS + "?subject=Repeat Prescriptions"}>
    Kingsthorpe.K83035@nhs.net
  </a>
);

export const OPENING_HOURS = "Monday - Friday, 8am - 6.30pm";

export const PAGES = {
  appointments: "/appointments",
  chaperones: "/chaperones",
  contactDetails: "/contact-details",
  complaints: "/complaints",
  homeVisits: "/home-visits",
  onlineAppointments: "/online-appointments",
  openingHours: "/opening-hours",
  outOfHours: "/out-of-hours",
  nhsApp: "/nhs-app",
  prescriptions: "/prescriptions",
  register: "/register",
  regulatoryNotices: "/regulatory-notices",
  sicknessCertificates: "/sickness-certificates",
  testResults: "/test-results",
  vaccinations: "/vaccinations",
};

export const PRACTICE_TRAINING_DAYS = [
  "2024-05-08",
  "2024-06-12",
  "2024-07-10",
  "2024-08-21",
  "2024-09-11",
  "2024-10-09",
  "2024-11-13",
  "2024-12-04",
  "2025-01-15",
  "2025-02-12",
  "2025-03-12",
];

export const TELEPHONE = <a href="tel:+441604713823">01604 713823</a>;
