import "../style/page.css";

export function Chaperones() {
  return (
    <>
      <div className="content-container">
        <div className="content">
          <div className="page-title">Chaperones</div>
          <p>
            We provide a chaperone service for patients, which is available
            during any consultation and surgery. This is to ensure that patients
            feel comfortable and secure during their appointments and that their
            dignity and privacy is upheld.
          </p>
          <p>
            Should you wish to have a chaperone present during your
            consultation, please speak to a receptionist or to your GP.
          </p>
        </div>
      </div>
    </>
  );
}
